import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public label: string,
    private dialogRef: MatDialogRef<ConfirmComponent>,
  ) { }

  ngOnInit() {
  }

  public returnReply(reply: string) {
    this.dialogRef.close(reply);
  }
}
