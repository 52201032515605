<div mat-dialog-title>Confirm that you want to delete {{ this.label }}</div>

<mat-dialog-content>

  <div>
    <div fxLayout="row" fxLayoutGap="10px">
      <button mat-raised-button color="primary" (click)="returnReply('yes')" style="width: 50%;">
        <mat-icon>check</mat-icon> &nbsp; Confirm
      </button>

      <button mat-raised-button (click)="returnReply('no')"
        style="width: 50%; background-color: #818181; color: white;">
        <mat-icon>close</mat-icon> &nbsp; Cancel
      </button>
    </div>
  </div>

</mat-dialog-content>